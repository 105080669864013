<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{translate('Suite schemas')}}
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-btn
        :loading="details.loading"
        text
        :to="'/suite-schemas/create-new'">
          <v-icon>create</v-icon> {{translate('New suite schema')}}
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <suites-table
          :loading="details.loading"
          :suites="details.suites"
          :selectedLabels="details.selectedLabels">
          <template v-slot:edit-item="{item}">
            <v-layout>
              <v-btn :to="`/suite-schema/${item.suiteId}`" small icon color="primary">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn :to="`/suite/${item.suiteId}`" small icon color="primary">
                <v-icon>fa-eye</v-icon>
              </v-btn>
            </v-layout>
          </template>
        </suites-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import SuitesTable from '@/components/suites/suites-table.vue'
export default {
  data: () => ({
  }),
  components: {
    LayoutPage,
    SuitesTable
  },
  computed: {
    ...mapGetters(['api', 'translate']),
  },
  asyncComputed: {
    details: {
      async get () {
        let {items} = await this.api.suites.getSuiteSchemas()
        return {
          loading: false,
          selectedLabels: [],
          suites: items
        }
      },
      default: {
        loading: true,
        selectedLabels: [],
        suites: []
      }
    }
  }
}
</script>
