<template>
  <v-data-table
    :headers="headers"
    :items="viableSuites"
    class="elevation-1">
    <template v-slot:top>
      <v-autocomplete
        v-model="selectedLabels"
        :label="translate('Search attribute')"
        :items="viableAttributes"
        item-text="label"
        item-key="name"
        multiple
      >
        <template v-slot:selection="{item}">
          <v-chip
            close
            @click:close="removeSelectedLabel(item)"
            >
            {{item.label}}
          </v-chip>
        </template>
      </v-autocomplete>
    </template>
    <template v-slot:body="{items}">
      <tbody>
        <tr v-for="item in items" :key="item.key">
          <td v-for="(h, i) in headers" :class="h.cls || 'text-left'" :key="i">
            <component
              :is="h.component"
              :header="h"
              :item="item"
              :items="items"
              />
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>
<script>
import {mapGetters} from 'vuex'
import TableDataCell from '@/components/table-cells/table-data-cell.vue'
import TableSlotCell from '@/components/table-cells/table-slot-cell.vue'
import TableAttributesCloudCell from '@/components/table-cells/table-attributes-cloud-cell.vue'

import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/sortBy'
export default {
  props: ['suites', 'selectedLabels', 'loading'],
  data: () => ({
  }),
  computed: {
    ...mapGetters(['api', 'suitesConfig', 'translate']),
    headers () {

      return [
        this.getActionEditHeaders(),
        this.getSuiteHeaders()
      ]
        .reduce((concatenated, list) => concatenated.concat(list || []), [])
        .filter(header => header)
    },
    viableAttributes () {
      let attributes = this.viableSuites.map(({attributes}) => attributes)
      return sortBy(
        uniqBy(
          flatten(attributes),
          ({label}) => label
        ),
        ({label}) => label
      )
    },
    viableSuites () {
      let selectedLabels = this.selectedLabels
      return this.mappedSuites
        .filter(({labels}) => selectedLabels.every(label => label in labels))
    },
    mappedSuites () {
      return (this.suites || [])
        .map(suite => ({
          suiteId: suite.suiteId,
          label: this.suitesConfig.getSuiteLabel(suite.suiteId),
          size: suite.size,
          attributes: this.suitesConfig.mapSuitetableAttributes(suite)
            .filter(({attribute}) => attribute.name !== suite.key)
            .map(({label, attribute}) => ({label: label.trim(), attribute}))
            .filter(({label}) => label)
          }))
        .map(o => ({
          ...o,
          labels: keyBy(o.attributes, 'label')
        }))
    }
  },
  methods: {
    getActionEditHeaders () {
      let slot = this.$scopedSlots['edit-item']
      if (slot) {
        return [{
          slot,
          width: '1%',
          text: '',
          value: 'internal.action-edit',
          sortable: false,
          component: TableSlotCell
        }]
      }
    },
    getSuiteHeaders () {
      return [{
        text: this.translate('Suite'),
        value: 'label',
        sortable: true,
        component: TableDataCell
      }, {
        text: this.translate('Size'),
        value: 'size',
        sortable: true,
        component: TableDataCell
      }, {
        text: this.translate('Attributes'),
        value: 'attributes',
        sortable: true,
        component: TableAttributesCloudCell
      }]
    },
    removeSelectedLabel ({label}) {
      let l = this.selectedLabels
      const index = l.indexOf(label)
      if (index >= 0) l.splice(index, 1)
    },
    suiteSelected (suite) {
      this.$emit('suite-selected', suite)
    }
  }
}
</script>
