<template>
  <div>
    <v-chip v-for="({label}) in Object.values(value)" :key="label">{{label}}</v-chip>
  </div>
</template>
<script>
import get from 'object-get'
export default {
  props: ['header', 'item'],
  computed: {
    value () { return get(this.item, this.header.value) },
  }
}
</script>
